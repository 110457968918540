/* eslint-disable */

//Skup koji sadrzi informaciju o scenamam linkHotspot, infoHotSpot, njihove pozcije 
var APP_DATA = {
  "scenes": [
    {
      "id": "room_1",
      "name": "House enter",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        }
      ],
      "faceSize": 2000,
      "initialViewParameters": {
        "yaw": 0.032755317498915915,
        "pitch": 0.4302140720078924,
        "fov": 1.2933824216075565
      },
      "linkHotspots": [
        {
          "yaw": 0.06461219947811259,
          "pitch": 0.8803471859472722,
          "rotation": 0,
          "target": "room_2"
        },
        {
          "yaw": 1.0896130884499833,
          "pitch": 0.6065884906705872,
          "rotation": 5.497787143782138,
          "target": "room_9"
        },
        {
          "yaw": 1.3627721352737918,
          "pitch": 0.46322684483185483,
          "rotation": 6.283185307179586,
          "target": "room_10"
        },
        {
          "yaw": 1.3714163347796315,
          "pitch": 0.8098437607694606,
          "rotation": 0.7853981633974483,
          "target": "room_11"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "room_2",
      "name": "Corridor",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        }
      ],
      "faceSize": 2000,
      "initialViewParameters": {
        "yaw": 0.1342125611490843,
        "pitch": 0.3829219423678829,
        "fov": 1.3715802068843215
      },
      "linkHotspots": [
        {
          "yaw": 0.02843077750514489,
          "pitch": 0.7189168161571686,
          "rotation": 0,
          "target": "room_3"
        },
        {
          "yaw": -3.0508331773366404,
          "pitch": 1.0124908521656426,
          "rotation": 0,
          "target": "room_1"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "room_3",
      "name": "Living room",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        }
      ],
      "faceSize": 2000,
      "initialViewParameters": {
        "pitch": 0,
        "yaw": 0,
        "fov": 1.5707963267948966
      },
      "linkHotspots": [
        {
          "yaw": 0.19788345705875976,
          "pitch": 0.6232729488435975,
          "rotation": 1.5707963267948966,
          "target": "room_7"
        },
        {
          "yaw": -0.9098591616004459,
          "pitch": 0.40161914218137085,
          "rotation": 4.71238898038469,
          "target": "room_4"
        },
        {
          "yaw": -3.119658164041546,
          "pitch": 0.41467535730435756,
          "rotation": 0,
          "target": "room_2"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "room_4",
      "name": "Dining room - 1",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        }
      ],
      "faceSize": 2000,
      "initialViewParameters": {
        "yaw": -0.5375321803902136,
        "pitch": 0.32477340222946793,
        "fov": 1.3715802068843215
      },
      "linkHotspots": [
        {
          "yaw": 0.15619520830411204,
          "pitch": 0.5343516836439548,
          "rotation": 1.5707963267948966,
          "target": "room_3"
        },
        {
          "yaw": -1.4887844144994595,
          "pitch": 0.5141617700609089,
          "rotation": 0,
          "target": "room_5"
        },
        {
          "yaw": -2.714950624585878,
          "pitch": 0.6547005854863297,
          "rotation": 0.7853981633974483,
          "target": "room_13"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "room_5",
      "name": "Dining room - 2",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        }
      ],
      "faceSize": 2000,
      "initialViewParameters": {
        "yaw": -0.6149783662682733,
        "pitch": 0.33312463640745094,
        "fov": 1.3715802068843215
      },
      "linkHotspots": [
        {
          "yaw": 1.44493222754613,
          "pitch": 0.8453524123294933,
          "rotation": 0,
          "target": "room_4"
        },
        {
          "yaw": -0.1171094515354234,
          "pitch": 0.47011538257449104,
          "rotation": 5.497787143782138,
          "target": "room_6"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "room_6",
      "name": "Kitchen",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        }
      ],
      "faceSize": 2000,
      "initialViewParameters": {
        "yaw": 0.3748392957032163,
        "pitch": 0.40988008475959603,
        "fov": 1.3715802068843215
      },
      "linkHotspots": [
        {
          "yaw": -1.7778178858119027,
          "pitch": 0.7597693372239398,
          "rotation": 4.71238898038469,
          "target": "room_5"
        },
        {
          "yaw": -1.4777180238002927,
          "pitch": 0.6968657477681095,
          "rotation": 0,
          "target": "room_12"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "room_7",
      "name": "Sleeping room - 1",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        }
      ],
      "faceSize": 2000,
      "initialViewParameters": {
        "yaw": 0.6399393325005569,
        "pitch": 0.06230645977770166,
        "fov": 1.3715802068843215
      },
      "linkHotspots": [
        {
          "yaw": 0.6422010537329044,
          "pitch": 0.3144122962142415,
          "rotation": 0.7853981633974483,
          "target": "room_3"
        },
        {
          "yaw": 0.38724231933524855,
          "pitch": 0.3292363391014064,
          "rotation": 0,
          "target": "room_8"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "room_8",
      "name": "Bathroom - 1",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        }
      ],
      "faceSize": 2000,
      "initialViewParameters": {
        "yaw": -2.37204071016839,
        "pitch": 0.3327560479519853,
        "fov": 1.3715802068843215
      },
      "linkHotspots": [
        {
          "yaw": -2.7480154645814103,
          "pitch": 0.6449329397227448,
          "rotation": 0.7853981633974483,
          "target": "room_7"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "room_9",
      "name": "Bathroom - 2",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        }
      ],
      "faceSize": 2000,
      "initialViewParameters": {
        "yaw": -1.2535042875837998,
        "pitch": 0.5245744966685688,
        "fov": 1.3715802068843215
      },
      "linkHotspots": [
        {
          "yaw": -2.9190325174089775,
          "pitch": 1.1089802969610787,
          "rotation": 0,
          "target": "room_1"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "room_10",
      "name": "Office",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        }
      ],
      "faceSize": 2000,
      "initialViewParameters": {
        "yaw": 0.059046662239081726,
        "pitch": 0.340568762360693,
        "fov": 1.3715802068843215
      },
      "linkHotspots": [
        {
          "yaw": -1.6859903803130845,
          "pitch": 0.7908225084028331,
          "rotation": 0,
          "target": "room_1"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "room_11",
      "name": "Sleeping room - 2",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        }
      ],
      "faceSize": 2000,
      "initialViewParameters": {
        "yaw": 0.04582934087388324,
        "pitch": 0.48938671031950776,
        "fov": 1.3715802068843215
      },
      "linkHotspots": [
        {
          "yaw": -1.5522069047830662,
          "pitch": 0.845554690464617,
          "rotation": 0,
          "target": "room_1"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "room_12",
      "name": "Wardrobe storage",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        }
      ],
      "faceSize": 2000,
      "initialViewParameters": {
        "yaw": 0.5496443163943585,
        "pitch": 0.552179680587848,
        "fov": 1.3715802068843215
      },
      "linkHotspots": [
        {
          "yaw": -0.15524567609833362,
          "pitch": 0.9840265756841724,
          "rotation": 6.283185307179586,
          "target": "room_6"
        },
        {
          "yaw": 1.3489506110805412,
          "pitch": 1.0483551359104855,
          "rotation": 0,
          "target": "room_13"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "room_13",
      "name": "Corridor",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        }
      ],
      "faceSize": 2000,
      "initialViewParameters": {
        "yaw": -1.0964524473634896,
        "pitch": 0.43104317589582486,
        "fov": 1.2933824216075565
      },
      "linkHotspots": [
        {
          "yaw": 1.5739856398593766,
          "pitch": 0.884298082057521,
          "rotation": 0,
          "target": "room_4"
        },
        {
          "yaw": -1.3618404472494774,
          "pitch": 0.5874574310819813,
          "rotation": 0,
          "target": "room_12"
        },
        {
          "yaw": -1.6942559022312125,
          "pitch": 1.328777376371697,
          "rotation": 5.497787143782138,
          "target": "room_14"
        }
      ],
      "infoHotspots": []
    },
    {
      "id": "room_14",
      "name": "Garage",
      "levels": [
        {
          "tileSize": 256,
          "size": 256,
          "fallbackOnly": true
        },
        {
          "tileSize": 512,
          "size": 512
        },
        {
          "tileSize": 512,
          "size": 1024
        },
        {
          "tileSize": 512,
          "size": 2048
        }
      ],
      "faceSize": 2000,
      "initialViewParameters": {
        "yaw": -0.42404325471507853,
        "pitch": 0.25837928676259025,
        "fov": 1.3715802068843215
      },
      "linkHotspots": [
        {
          "yaw": -2.1991643370868506,
          "pitch": 0.6174969243242607,
          "rotation": 5.497787143782138,
          "target": "room_13"
        }
      ],
      "infoHotspots": []
    }
  ],
  "name": "Tara House VR",
  
};

export default APP_DATA;