/* eslint-disable */

import APP_DATA from './data';

const Marzipano = require('../../vendor/marzipano')

'use strict';


// Pocetna inicijalizacija
let viewer = new Marzipano.Viewer(document.getElementById('pano'));

// Pribavljanje potrebnih elemenata iz DOM-a
let autorotateToggleElement = document.querySelector('#autorotateToggle');


//Rezultujuci niz koji će sadržati sve scene
let scenes_final = [];
  
//Prolazak kroz skup podataka koji sadrži sve potrebne informacije
for(let i=0; i < APP_DATA.scenes.length; i++) {
  // Definisanje sourca odakle ce biti učitana slika
  /*let source = Marzipano.ImageUrlSource.fromString("images/room_" + (i+1) + ".jpg");*/
  
  var urlPrefix = "tiles";
  var source = Marzipano.ImageUrlSource.fromString(
    urlPrefix + "/"+ APP_DATA.scenes[i].id +"/{z}/{f}/{y}/{x}.jpg",
    { cubeMapPreviewUrl: urlPrefix + "/"+ APP_DATA.scenes[i].id +"/preview.jpg" });

  /*console.log(urlPrefix + "/" + APP_DATA.scenes[i].id);*/

  var geometry = new Marzipano.CubeGeometry(APP_DATA.scenes[i].levels);
    
  // Kreiranje geometrije same scene
  /*let geometry = new Marzipano.EquirectGeometry([{ width: 1000 }]);*/

  // Kreiranje pogleda
  let limiter = Marzipano.RectilinearView.limit.traditional(APP_DATA.scenes[i].faceSize, 160*Math.PI/180, 100*Math.PI/180);
  let view = new Marzipano.RectilinearView(APP_DATA.scenes[i].initialViewParameters, limiter);
    
  // Kreiranje same scene pri čemu se prosleđuju gore definisani parametri
  let scene = viewer.createScene({
      source: source,
      geometry: geometry,
      view: view,
      pinFirstLevel: true
  });

  // Kreiranje link hotspotva, na osnovu gore definisanog skupa podataka citaju se link hotspotovi 
  // i na osnovu pomocne funkcije, vrsi se kreiranje html elementa koji se appenduje u okviru
  // DOM-a
  APP_DATA.scenes[i].linkHotspots.forEach(function(hotspot) {
      let element = createLinkHotspotElement(hotspot);
      scene.hotspotContainer().createHotspot(element, { yaw: hotspot.yaw, pitch: hotspot.pitch });
  });
  
  // Kreiranje info hotspotva, na osnovu gore definisanog skupa podataka citaju se info hotspotovi 
  // i na osnovu pomocne funkcije, vrsi se kreiranje html elementa koji se appenduje u okviru
  // DOM-a
  APP_DATA.scenes[i].infoHotspots.forEach(function(hotspot) {
      let element = createInfoHotspotElement(hotspot);
      scene.hotspotContainer().createHotspot(element, { yaw: hotspot.yaw, pitch: hotspot.pitch });
  });
    
  //Dodavanje scene u rezultujuci niz, posto se koristi funkcija findSceneById, pored same scene
  //dodaje se i id iste
  scenes_final.push({"scena": scene, "id": APP_DATA.scenes[i].id});
}

// Deo kojim se startuje autorotacije
let autorotate = Marzipano.autorotate({
  yawSpeed: 0.1,         // Brzina rotacije
  targetPitch: 0,        // Pitch vrednost kojoj se tezi
  targetFov: Math.PI/2   // Fov vrednost kojoj se tezi
});
  
// Autorotacije se startuje nakon 4 sekunde
viewer.setIdleMovement(4000, autorotate);  
// Ovim se sam proces pokrece
viewer.startMovement(autorotate); 

// Definisanje ponasanje kada se klikne na dugme za autorotate
autorotateToggleElement.classList.add('enabled');
autorotateToggleElement.addEventListener('click', toggleAutorotate);


//Inicijalno se kreće od prve scene
scenes_final[0]["scena"].switchTo();

//Klik na neku od stavki u okviru dostupnih scena
//vrsi promenu scene
scenes_final.forEach(function(scene) {
    
  let el = document.querySelector('#sceneList .sceneListElement[data-id="' + scene.id + '"]');
    
  el.addEventListener('click', function() {  
    
    switchScene(scene);

    //Scena koja je trenutno aktivna imace boldovana slova u nazivu
    let sceneList = document.getElementsByClassName("sceneListElements")[0];

    for(let i=0; i < sceneList.childElementCount; i++) {
      if(sceneList.children[i].classList.contains('active')) {
        sceneList.children[i].classList.remove('active');
      }
    }
    this.classList.add("active");
  });
});

//Funkcija kojom se vrsi promena na scenu koja je prosledjena kao
//parametar dole navedene funkcije
function switchScene(scene) {
  scene.scena.switchTo();
  let el = document.querySelector('#sceneList .sceneListElement[data-id="' + scene.id + '"]');
  
  //Scena koja je trenutno aktivna imace boldovana slova u nazivu
  let sceneList = document.getElementsByClassName("sceneListElements")[0];

  for(let i=0; i < sceneList.childElementCount; i++) {
    if(sceneList.children[i].classList.contains('active')) {
      sceneList.children[i].classList.remove('active');
    }
  }
  
  el.classList.add('active');
}

//Funkcija za kreiranje hotspota koji vodi ka nekoj drugoj sceni,
//ova vrsta hot spotova su link hotspotovi
function createLinkHotspotElement(hotspot) {

  //Kontejner koji ce sadrzati text i link ka novoj
  //sceni i dodavanje potrebnih klasa uz pomoc kojih
  //ce se vrsiti stilizovanje
  let wrapper = document.createElement('div');
  wrapper.classList.add('hotspot', 'link-hotspot');
    
  //Ikonica u okviru hotspot-a, dodavanje same ikonice
  //kao i klase koja ce sluziti za stilizovanje
  let icon = document.createElement('img');
  icon.src = 'icons/link.png';
  icon.classList.add('link-hotspot-icon');

  //Transformacije, ovaj deo sam video u okviru dokumentacije
  let transformProperties = [ '-ms-transform', '-webkit-transform', 'transform' ];
  for (let i = 0; i < transformProperties.length; i++) {
    let property = transformProperties[i];
    icon.style[property] = 'rotate(' + hotspot.rotation + 'rad)';
  }

  //Dodavanje event-a da kada se klikne, taj klik vodi ka novoj sceni
  wrapper.addEventListener('click', function() {
    switchScene(findSceneById(hotspot.target));
  });

  //Ovo je takodje preuzeto iz dokumentacije
  stopTouchAndScrollEventPropagation(wrapper);

  //Kreiranje samog tool tipa sa tekstom
  let tooltip = document.createElement('div');
  //Klase za stilizovanje
  tooltip.classList.add('hotspot-tooltip', 'link-hotspot-tooltip' );
  //Dodavanje teksta
  tooltip.innerHTML = findSceneDataById(hotspot.target).name;

  //Appendovanje u okvoru HTML-a
  wrapper.appendChild(icon);
  wrapper.appendChild(tooltip);

  return wrapper;
}


//Funkcija za kreiranje hotspota koji sadrzi infomacije o necumu sta je na sceni,
//ova vrsta hot spotova su info hotspotovi
function createInfoHotspotElement(hotspot) {

    //Kontejner koji ce sadrzati text i link ka novoj
    //sceni i dodavanje potrebnih klasa uz pomoc kojih
    //ce se vrsiti stilizovanje
    let wrapper = document.createElement('div');
    wrapper.classList.add('hotspot', 'info-hotspot');
  
    //Kreiranje heder-a samog hotspota
    let header = document.createElement('div');
    header.classList.add('info-hotspot-header');

    //Deo sa ikonicom
    let iconWrapper = document.createElement('div');
    iconWrapper.classList.add('info-hotspot-icon-wrapper');
    let icon = document.createElement('img');
    icon.src = 'icons/info.png';
    icon.classList.add('info-hotspot-icon');
    iconWrapper.appendChild(icon);

    // Naslov u okviru header dela
    let titleWrapper = document.createElement('div');
    titleWrapper.classList.add('info-hotspot-title-wrapper');
    let title = document.createElement('div');
    title.classList.add('info-hotspot-title');
    title.innerHTML = hotspot.title;
    titleWrapper.appendChild(title);

    // Kreiranje elementa za zatvaranje hotspot-a
    let closeWrapper = document.createElement('div');
    closeWrapper.classList.add('info-hotspot-close-wrapper');
    let closeIcon = document.createElement('img');
    closeIcon.src = 'icons/close.png';
    closeIcon.classList.add('info-hotspot-close-icon');
    closeWrapper.appendChild(closeIcon);

    // Dodavanje html elementa u okviru header-a
    header.appendChild(iconWrapper);
    header.appendChild(titleWrapper);
    header.appendChild(closeWrapper);

    // Kreiranje dela sa tekstom
    let text = document.createElement('div');
    text.classList.add('info-hotspot-text');
    text.innerHTML = hotspot.text;

    // Dodavanje header-a i tekstualnog dela u okviru wrapper-a
    wrapper.appendChild(header);
    wrapper.appendChild(text);

    let toggle = function() {
      wrapper.classList.toggle('visible');
    };

    // Prikazivanje elementa kada se klikne na header deo hotspot-a
    wrapper.querySelector('.info-hotspot-header').addEventListener('click', toggle);

    //Ovo je takodje preuzeto iz dokumentacije
    stopTouchAndScrollEventPropagation(wrapper);

    return wrapper;
}

//----------------------------------------------------
//POMOCNE FUNKCIJE KOJE SE KORISTE U GORNJEM DELU KODA
//----------------------------------------------------

//Pronalazanje potrebne scene, funkcija se koristi kod link hotspot-a kada se vrsi prelazak sa jedne na drugu scenu
function findSceneById(id) {
    for (let i = 0; i < scenes_final.length; i++) { 
        if (scenes_final[i].id === id) {
            return scenes_final[i];
        }
    }
    return null;
  }

//Pronalazanje potrebne imena scene, funkcija se koristi kod link hotspot-a kada se dodaje tekst u okviru tooltip-a
function findSceneDataById(id) {
  for (let i = 0; i < APP_DATA.scenes.length; i++) {
    if (APP_DATA.scenes[i].id === id) {
      return APP_DATA.scenes[i];
    }
  }
  return null;
}

//Iz dokumentacije, odnosno referentnog porjekta.
function stopTouchAndScrollEventPropagation(element, eventList) {
  var eventList = [ 'touchstart', 'touchmove', 'touchend', 'touchcancel', 'wheel', 'mousewheel' ];
    for (let i = 0; i < eventList.length; i++) {
      element.addEventListener(eventList[i], function(event) {
      event.stopPropagation();
    });
  }
}

//Definisanje ponasanja koje se desca klikom na dugme za autorotate
function toggleAutorotate() {
  //pokrenuto je pa se zaustavlja
  if (autorotateToggleElement.classList.contains('enabled')) {
    autorotateToggleElement.classList.remove('enabled');
    stopAutorotate();
  //zaustavljeno je pa se pokrece
  } else {
    autorotateToggleElement.classList.add('enabled');
    startAutorotate();
  }
}

//----------------------------------------------------
//POMOCNE FUNKCIJE KOJE SE KORISTE U FUNKCIJI 
//TOGGLEAUTOROTATE()
//----------------------------------------------------

//Pokretanje autorotacije kada se klikne na dugme, a ona je disable-ovana
function startAutorotate() {
  if (!autorotateToggleElement.classList.contains('enabled')) {
    return;
  }
  viewer.startMovement(autorotate);
  viewer.setIdleMovement(4000, autorotate);
}

//Zaustavljanje autorotacije
function stopAutorotate() {
  viewer.stopMovement();
  viewer.setIdleMovement(Infinity);
}


 



